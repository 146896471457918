import React from "react";
import { useEffect, useState } from "react";
import {
  getFirestore,
  doc,
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";
import db from "../firebase";
import { useAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";

const MyPolls = () => {
  const [polls, setPolls] = useState([]);
  const user = useAuth();
  const uid = user ? user.uid : null;
  useEffect(() => {
    const fetchPolls = async () => {
      if (uid) {
        const data = await getDocs(
          query(collection(db, "polls"), where("poll_by_uid", "==", uid))
        );
        setPolls(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      }
    };

    fetchPolls();
  }, [uid]);
  return (
    <div className="my_polls_main">
      <h1>My Polls</h1>

      <div className="polls">
        {user ? (
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Activity</th>
                <th>Total Votes</th>
                <th>Poll Result</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {polls.map((poll) => (
                <tr key={poll.id}>
                  <td>{poll.title}</td>
                  <td>{poll.date}</td>
                  <td>{poll.live_count ? "Active" : "Inactive"}</td>
                  <td>{poll.total_votes}</td>
                  <td>
                    <Link to={`/poll/${poll.id}`}>View Result</Link>
                  </td>
                  <td>
                    <span
                      className="delete_btn"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this poll?"
                          )
                        ) {
                          deleteDoc(doc(db, "polls", poll.id));
                          setPolls(polls.filter((p) => p.id !== poll.id));
                        } else {
                          return false;
                        }
                      }}
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <span className="alter_description">
            Please login to view your polls
          </span>
        )}
      </div>
    </div>
  );
};

export default MyPolls;
